import React, { Component } from "react";
import { isOn } from "../../utils/stat";
import { fieldSorter } from "./func1";
//#region
const {
  레슨시작,
  레슨마침,
  학원도착,
  하원,
  등원,
  결석
} = require("../../gma/Stats");
//#endregion
export const filter = (students, day, onoff, time) =>
  //#region
  students.filter(m => {
    let ignore = onoff === "on" ? "i" + day + "ignore" : "o" + day + "ignore"
    let field = onoff === "on" ? "it" : "ot"
    field = field + day
    return m[field] === time && (m[ignore] === undefined || (m[ignore] !== undefined && m[ignore] === false))
  });
//#endregion
export function ordering(order, students) {
  //#region
  if (order === "name") students = 이름순(students);
  if (order === "hak") students = 학년순(students);
  if (order === "time") students = 시간순(students);
  return students;
}
//#endregion
export function tagColor_Time(students, day) {
  //#region
  //지난주에 결석한 학생은 등원시각이 없으므로 그전주의 등원시각을 읽어서 다른학생과 같이 소트할수있도록 준비한다.
  // *** 앞으로는 지난주가 그전주나 모두 필드명으로 날짜를 사용하도록 고쳐야한다. 또한 지난 알자 데이타를 많이 넣어놓고 반드시 등원시각을 가져올수 있도록한다.
  // let day1 = day2(day); // 요일을 한글로 저장
  students = students.map(m => {
    return today(m, day);
  });
  return students;
}
//#endregion
export function getClasses(student, btnSize, badgeSize) {
  //#region
  let class1 = `btn mr-${btnSize + 1} mb-${btnSize + 1} p-${btnSize} btn-`;
  let class2 = "warning";
  if (isOn(student, 등원)) {
    class2 = "danger";
  }
  if (isOn(student, 레슨시작)) {
    class2 = "primary";
  }
  if (isOn(student, 레슨마침)) {
    class2 = "success";
  }
  if (isOn(student, 하원)) {
    class2 = "light";
  }
  if (isOn(student, 결석)) {
    class2 = "secondary";
  }

  return class1 + class2;
}

//#endregion
export function showName(m) {

  function getName(total, name) {
    return total + "," + name;
  }
  const name = m.name.reduce(getName);
  return (
    <span className={`m-1 p-1 badge badge-light`}>
      {name}
    </span>
  );
}
//#endregion
export function showTime(m) {
  //#region
  return (
    <span className={`m-1 p-1 badge badge-light`}>
      {m}
    </span>
  );
}
//#endregion
export function getTodayDateName() {
  //#region
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  //=================================
  // 요일번호를 day로 가져와서 2일자에 더해서 필드명을 구한다.
  // 앞으로 지나간 등원시각을 모두 다른 컬렉션으로 넣어놓고, 등원시각을 찾을때까지 과거로 검색하게 고칠것.
  // 2인 이유: 현재 db에 필드명으로 1203까지 들어있다.
  //=================================

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return yyyy.toString() + mm.toString() + dd.toString();
}
//#endregion
export function today(student, day) {
  //#region
  // var today = new Date();
  // var dd = today.getDate();
  // var mm = today.getMonth() + 1; //January is 0!
  // var yyyy = today.getFullYear();
  //=================================
  // 요일번호를 day로 가져와서 2일자에 더해서 필드명을 구한다.
  // 앞으로 지나간 등원시각을 모두 다른 컬렉션으로 넣어놓고, 등원시각을 찾을때까지 과거로 검색하게 고칠것.
  // 2인 이유: 현재 db에 필드명으로 1203까지 들어있다.
  //=================================

  // if (dd < 10) {
  //   dd = "0" + dd;
  // }
  // if (mm < 10) {
  //   mm = "0" + mm;
  // }
  //  ""를 더해서 숫자를 string으로 만듬
  let date1 = "0118"; //mm + "" + dd - 7;
  let date2 = "0111"; //mm + "" + dd - 14;
  let date3 = "0104"; //mm + "" + dd - 21;
  let date4 = "1221"; //mm + "" + dd - 28;
  let date5 = "1214"; //mm + "" + dd - 28;
  if (student.time !== undefined) {
    if (student.time[date1] !== undefined && student.time[date1] !== "") {
      student.color = ""; //
      student.new_time = student.time[date1]; // 그전주 등원시각:
    } else if (
      student.time[date2] !== undefined &&
      student.time[date2] !== ""
    ) {
      student.color = "1"; // 2주전을 의미
      student.new_time = student.time[date2]; // 그전전주 등원시각:
    } else if (
      student.time[date3] !== undefined &&
      student.time[date3] !== ""
    ) {
      student.color = "2"; //3주전을 의미
      student.new_time = student.time[date3]; // 그전전전주 등원시각:
    } else if (
      student.time[date4] !== undefined &&
      student.time[date4] !== ""
    ) {
      student.color = "3";
      student.new_time = student.time[date4]; // 그전전전전주 등원시각:
    } else if (
      student.time[date5] !== undefined &&
      student.time[date5] !== ""
    ) {
      student.color = "4";
      student.new_time = student.time[date5]; // 그전전전전전주 등원시각:
    } else {
      student.color = "";
      student.new_time = ""; // sort가 끊어지지않도록 undefined 를 만들지 않는다.
    }
  }
  return student;
}
//#endregion
export function 이름순(students) {
  //#region
  students.sort((a, b) => {
    // 먼저 'name'으로 정렬
    // const nameComparison = a.name.localeCompare(b.name);
    // if (nameComparison !== 0) {
      
    //   return nameComparison;
    // }

    // 'status'가 1280인 학생을 맨 앞으로 이동
    if (a.status === 1280 && b.status !== 1280) {
      return -1; // a를 앞으로
    }
    if (a.status !== 1280 && b.status === 1280) {
      return 1; // b를 앞으로
    }

    return 0; // 나머지는 그대로 유지
  });

    // 디버깅: 정렬된 students 배열 출력
    console.log("정렬된 students 배열:", students);

    return students;
}
//#endregion
export function 학년순(students) {
  //#region
  let students_연령 = [...students];
  students_연령 = students_연령.filter(m => m.age > 0);
  students_연령 = students_연령.sort(fieldSorter(["age", "name"]));

  let students_학년 = [...students];
  students_학년 = students_학년.filter(m => m.hag > 0);
  students_학년 = students_학년.sort(fieldSorter(["hag", "name"]));

  return (students = [...students_연령, ...students_학년]);
}
//#endregion
export function 시간순(students) {
  //#region
  students = students.sort(fieldSorter(["new_time", "name"]));
  let students_noTime = [...students];
  students_noTime = students_noTime.filter(m => m.new_time === "");
  let students_time = [...students];
  students_time = students_time.filter(m => m.new_time !== "");
  return (students = [...students_time, ...students_noTime]);
  // return students;
}
//#endregion
export function 시간표시(student, showTime, badgeSize) {
  //#region
  if (showTime) {
    return (
      <>
        {(student.time === "00:00" || !student.time) && (
          <span className={`m-${badgeSize} p-${badgeSize} badge badge-danger`}>
            기록없음
          </span>)}
        {student.time !== "00:00" && (
          <span className={`m-${badgeSize} p-${badgeSize} badge badge-success`}>
            {student.time}
          </span>)}
        <span className={`m-${badgeSize} p-${badgeSize} badge badge-info`}>
          {student.color}
        </span>
      </>
    );
  } else {
    return null;
  }
}
//#endregion
export function 학년표시(student, showHak, btnSize, badgeSize) {
  //#region
  if (showHak) {
    return (
      <span className={`m-${badgeSize}  p-${badgeSize} badge badge-info`}>
        {student.hag ? student.hag : student.age ? "-" + student.age : null}
      </span>
    );
  } else {
    return null;
  }
}
//#endregion
export function 픽업표시(student, day, btnSize, badgeSize) {
  //#region
  const place = "ip" + day;
  const time = "it" + day;

  if (!isOn(student, 학원도착)) {
    return (
      <span className={`m-${badgeSize}  p-${badgeSize} badge badge-danger`}>
        {String(student[place]).substring(0, 1) === "*"
          ? student[time] + " " + String(student[place]).substring(1)
          : null}
      </span>
    );
  } else {
    return null;
  }
}
//#endregion
export function 동행표시(student, day, btnSize, badgeSize) {
  //#region
  const place = "op" + day;
  const time = "ot" + day;

  if (isOn(student, 하원)) {
    return null;
  } else if (isOn(student, 레슨시작)) {
    return (
      <span className={`m-${badgeSize}  p-${badgeSize} badge badge-danger`}>
        {String(student[place]).substring(0, 1) === "*"
          ? student[time] + " " + String(student[place]).substring(1)
          : null}
      </span>
    );
  } else {
    return null;
  }
}
//#endregion

const moment = require("moment-timezone");
function extract12HourTimeFromISODate(isoDate) {
  return moment(isoDate)
    .tz("Asia/Seoul")
    .format("h:mm"); // 12시간 형식, 오전/오후 제거
}

export function 레슨대기자표시(student, btnSize, badgeSize) {
  //#region
  return (
    <>
      <span className={`m-${badgeSize} p-${badgeSize} badge badge-light`}>
        {student.onTime && extract12HourTimeFromISODate(student.onTime) + "→" + extract12HourTimeFromISODate(student.offTime)}
      </span>
    </>
  );
}
//#endregion
export function 레슨중표시(student, btnSize, badgeSize) {
  //#region
  return (
    <>
      <span className={`m-${badgeSize}  p-${badgeSize} badge badge-light`}>
        {student.onTime && extract12HourTimeFromISODate(student.onTime) + "→" + extract12HourTimeFromISODate(student.offTime)}
      </span>
    </>
  );
}
//#endregion
export function 레슨마침표시(student, btnSize, badgeSize) {
  //#region
  return (
    <>
      <span className={`m-${badgeSize}  p-${badgeSize} badge badge-light`}>
        {student.onTime && extract12HourTimeFromISODate(student.onTime) + "→" + extract12HourTimeFromISODate(student.offTime)}
      </span>
    </>
  );
}
//#endregion
export function 미등원배지표시(
  //#region
  //#region
  student,
  showTime,
  showHak,
  btnSize,
  badgeSize
) {
  return (
    <>
      {시간표시(student, showTime, badgeSize)}
      {학년표시(student, showHak, btnSize, badgeSize)}
      {상태표시(student, btnSize, badgeSize)}
    </>
  );
}
//#endregion
//#endregion
export function 상태표시(student, btnSize, badgeSize) {
  //#region
  return (
    <>
      <span className={`m-${badgeSize}  p-${badgeSize} badge badge-light`}>
        {!isOn(student, 하원) &&
          !isOn(student, 레슨시작) &&
          !isOn(student, 등원) &&
          isOn(student, 학원도착)
          ? "도착"
          : null}
      </span>
      <span className={`m-${badgeSize}  p-${badgeSize} badge badge-light`}>
        {!isOn(student, 하원) && !isOn(student, 레슨시작) && isOn(student, 등원)
          ? "등원"
          : null}
      </span>
      <span className={`m-${badgeSize}  p-${badgeSize} badge badge-light`}>
        {!isOn(student, 하원) && isOn(student, 레슨시작) ? "레슨" : null}
      </span>
    </>
  );
}
//#endregion
export function 배지표시(student) {
  //#region
  if (student.myBadge) {
    return student.myBadge;
  } else {
    return null;
  }
}
//#endregion
export function 버튼표시(student) {
  //#region
  if (student.myButton) {
    return student.myButton;
  } else {
    return null;
  }
}
//#endregion
export function counter(students, btnSize, badgeSize) {
  //#region
  return (
    <>
      <div className={`badge badge-secondary mx-${badgeSize}`}>
        {/* 출석: */}
        {"출 " +
          students.filter(m => isOn(m, 등원) || isOn(m, 하원)).length +
          "/" +
          students.length}
      </div>
      <div className={`badge badge-warning mx-${badgeSize}`}>
        {/* 올학생: */}
        {"미 " +
          students.filter(
            m => !isOn(m, 등원) && !isOn(m, 하원) && !isOn(m, 결석)
          ).length}
      </div>
      <div className={`badge badge-success mx-${badgeSize}`}>
        {/* 학원에: */}
        {"학 " + students.filter(m => isOn(m, 등원) && !isOn(m, 하원)).length}
      </div>
      <div className={`badge badge-secondary mx-${badgeSize}`}>
        {/* 하원: */}
        {"하 " + students.filter(m => isOn(m, 하원)).length}
      </div>
      <div className={`badge badge-danger mx-${badgeSize}`}>
        {/* 하원: */}
        {"결 " + students.filter(m => isOn(m, 결석)).length}
      </div>
    </>
  );
}

export const makeYear = () => {
  //#region
  var d = new Date();
  var year1 = d.getFullYear();
  const year = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  let dates = {};
  let monthNum = 0;
  year.map(m => {
    monthNum = monthNum + 1;
    dates[monthNum] = [];
    for (let i = 1; i < m + 1; i++) {
      dates[monthNum].push(
        year1 + String(monthNum).padStart(2, "0") + String(i).padStart(2, "0")
      );
    }
    return null
  });
  return dates;
};
//todo: 음악데이타에서 검색해서 입력하는 루틴 만들자.
//#endregion
export const getDates = (year1, month, start, end) => {
  //#region
  let dates = {};
  let monthNum = 0;
  const year = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  year.map(m => {
    monthNum = monthNum + 1;
    if (monthNum === month) {
      dates = [];
      for (let i = start; i < end + 1; i++) {
        dates.push(
          year1 + String(monthNum).padStart(2, "0") + String(i).padStart(2, "0")
        );
      }
    }
    return null
  });
  return dates;
};
//#endregion
export class MonthButtons extends Component {
  //#region
  month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  render() {
    return this.month.map(m => (
      <button
        key={m}
        className={
          m === this.props.month ? "btn m-2 btn-warning" : "btn m-2 btn-light"
        }
        onClick={() => this.props.onClickMonth(m)}
      >
        {m}월
      </button>
    ));
  }
}
//#endregion
export const getTeacherName = name => {
  if (name === "YB") return "예본쌤";
  if (name === "HJ") return "화진쌤";
  if (name === "HR") return "혜란쌤";
  if (name === "ES") return "은솔쌤";
  if (name === "SY") return "선영쌤";
};
