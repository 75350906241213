import { setStatus } from "../../utils/stat";

const { 결석 } = require("../../gma/Stats");

const submitable = student => {
  let student1 = { ...student };
  delete student1.time;
  delete student1.color;
  delete student1.new_time;
  delete student1.onTime;
  delete student1.myBadge;
  delete student1.myButton;
  return student1;
};
const doSubmit = async (student, user, socket) => {
  socket.emit(
    "status",
    {
      user: user,
      name: student.name,
      _id: student._id,
      status: student.status
    },
    function (err) {
      //      if (err) {
      //        this.props.history.push("/");
      //      }
    }
  );
};

export const Submit = (student, stat, thisScope, socket) => {
  // 테스트해보고 잘되면 더 밖으로 빼서 탑승명단에서도 공통으로 사용한다. 실험이 필요하고 반드시 성공시키자^^
  let chuls = [...thisScope.state.chuls];
  const index = chuls.indexOf(student);
  if (stat === 0 || stat === 결석) {
    student.status = stat;
  } else {
    student = setStatus(student, stat);
  }
  chuls[index] = student;
  thisScope.setState({ chuls }); //submit보다 먼저 해서 빠른 화면업데이트를 성취.
  const newStudent = { ...student };
  doSubmit(submitable(newStudent), thisScope.props.user._id, socket); // db에서 안받는 필드를 뺀다
};

export const cutClass = (student, thisScope, socket) =>
  Submit(student, 결석, thisScope, socket); //결석처리 = 상태를 결석으로 만듬
export const initStat = (student, thisScope, socket) => Submit(student, 0, thisScope, socket); // 최초상태로 만듬(모든 상태를 지움)
export const evaluate = (student, historyPush) => {
  historyPush(`/evalsOnly/${student._id}`);
};



