import React, { Component } from "react";
import fileName from "../common/func2";
import { btnStyle1 } from "../common/setting";
import { withRouter } from "react-router-dom";
import EvalsTable from "./evalsTable";
import Pagination from "../common/pagination";
import auth from "../../services/authService";
import { getStudents, saveStudent1 } from "../../services/evalsService";
import { getSetting } from "../../services/settingService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";
import BanSelector from "../common/banSelector";
import FieldSelector from "../common/fieldSelector";
//todo:평가를 편집하면 레슨에 초록으로 되는 문제를 없애자

// 전달 평가 데이터 올리는 루틴: evals.txt 참고하세요.
// 새달로 맞추기 위해서는 setting collection 에서 curMonth,prevMonth 를 설정하시오.
class Evals extends Component {
  state = {
    students: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
    ban: "",
    field: "모두"
  };
  user = auth.getCurrentUser();

  async componentDidMount() {
    const { data: students } = await getStudents();

    let did = false;
    if (this.props.location !== undefined) {
      if (this.props.location.state !== undefined && this.props.location.state !== null) {
        if (this.props.location.state[0] !== undefined) {
          if (this.props.location.state[0].field) {
            did = true;
            let students1 = [...students];
            if (!this.user.isPrincipal) {
              students1 = students1.filter(
                m =>
                  m.pianoBan ===
                  (this.props.user.name === "HR" ||
                    this.props.user.name === "YB"
                    ? "베토벤반"
                    : "바하반")
              );
            }
            this.setState({
              students: students1,
              ban: this.props.location.state[0].ban,
              field: this.props.location.state[0].field
            });
            window.scrollTo(0, this.props.location.state[0].scrollHeight);
          }
        }
      }
    }
    const { setting } = getSetting().then(({ data }) => {
      this.setState({ setting: data[0] })
    });
    if (!did) {
      let students1 = [...students];
      if (!this.user.isPrincipal) {
        students1 = students1.filter(
          m =>
            m.pianoBan ===
            (this.props.user.name === "HR" || this.props.user.name === "YB"
              ? "베토벤반"
              : "바하반")
        );
      }
      this.setState({ students: students1, setting });
    }
  }

  handleClick = id => {
    this.props.history.replace(`/evals`, [
      {
        ban: this.state.ban,
        field: this.state.field,
        scrollHeight: document.documentElement.scrollTop
      }
    ]);
    this.props.history.push(`/evals/${id}`);
  };

  handleLike = student => {
    const students = [...this.state.students];
    const index = students.indexOf(student);
    students[index] = { ...students[index] };
    students[index].liked = !students[index].liked;
    this.setState({ students });
  };
  handleSelectBan = ban => {
    this.setState({ ban });
  };
  handleSelectField = field => {
    this.setState({ field });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleClickItem = (item, changedItem) => {
    let students = [...this.state.students];
    const index = _.findIndex(this.state.students, item);
    students.map(m => {
      if (m.edit) {
        delete m.edit;
        let a = {};
        a._id = m._id;
        a.letter = m.letter;
        a.pJin = m.pJin;
        a.theory = m.theory;
        a.eval = m.eval;
        a.name = m.name;
        saveStudent1(a);
      }
      return true
    });
    students[index].edit = true;
    this.setState({ students });
  };

  handleChanged = (id, path, value) => {
    const students = [...this.state.students];
    const ids = students.map(s => s._id);
    const index = ids.indexOf(id);
    students[index] = { ...students[index] };
    students[index][path] = value;
    this.setState({ students });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      students: allStudents
    } = this.state;

    let filtered = allStudents;
    if (searchQuery)
      filtered = allStudents.filter(
        m =>
          m.name !== null &&
          m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const students = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: students };
  };
  user = auth.getCurrentUser();

  // componentWillUpdate() {
  //   const { clientHeight, scrollTop, scrollHeight } = document.documentElement
  //   this.scrolledUp = clientHeight + scrollTop < scrollHeight
  // }

  // scroll() {
  //   if (this.props.location.state[0].scrollHeight)
  //     window.scrollTo(0, this.props.location.state[0].scrollHeight);
  // window.scrollTo(0, document.documentElement.scrollHeight)
  // }

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: students } = this.getPagedData();
    return (
      <>
        {fileName("evals.jsx", this.user)}
        {/* 원본있음: evals20200726orig.jsx */}
        <span className="bg-success">
          <BanSelector
            ban={this.state.ban}
            onSelectBan={this.handleSelectBan}
          />
        </span>
        <span className="bg-light"> </span>
        <span className="bg-secondary" style={{ marginRight: 8 }}>
          <FieldSelector
            field={this.state.field}
            onSelectField={this.handleSelectField}
          />
        </span>
        {/* {/* //todo: Change to <Ibtn/> */}
        <i class="fas fa-bus-alt fa-lg" onClick={() => {
          this.props.history.push("/rides");
        }} style={btnStyle1}></i>
        {/* {/* //todo: Change to <Ibtn/> */}
        <i class="fas fa-chalkboard-teacher fa-lg" onClick={() => {
          this.props.history.push("/Chuls5");
        }} style={btnStyle1}
        ></i>

        <SearchBox value={searchQuery} onChange={this.handleSearch} />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />

        <EvalsTable
          setting={this.state.setting}
          user={this.user}
          students={students.filter(m =>
            this.state.ban === "" ? m : m.pianoBan === this.state.ban + "반"
          ).filter(m => m.pianoBan !== "" && m.otherBan !== "바" && m.currentStudent === true && m.schoolLong !== "성인"
          )}
          field={this.state.field}
          sortColumn={sortColumn}
          onLike={this.handleLike}
          onDelete={this.handleDelete}
          // onEvaluate={this.handleEvaluate}
          onSort={this.handleSort}
          onClick={this.handleClick}
          onClickItem={(item, changedItem) =>
            this.handleClickItem(item, changedItem)
          }
          onChanged={(id, path, value) => this.handleChanged(id, path, value)}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />

        {/* </div> */}
        {/* </div> */}
      </>
    );
  }
}

export default withRouter(Evals);
