import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";

const NavBar = ({ version, UserContext, onUserChange }) => {
  //todo:react-spring 적용시키자
  //todo:admin page 만들자. 모든 프로그램 다있는.
  //todo:탑승명단에 메세지 표시하는란을 상단에 만들자.
  //todo:탑승명단에 방학중 쉬는 학생 표시안되는 기능 만들자.
  //todo:탑승명단에 차개념 넣자. 한차정원 11명 유지. 한코스 도는 차안에 등원 하원 순서대로 표시하기
  //todo:탑승명단에 승차 하차 순서만들기
  const user = useContext(UserContext);

  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
      {/* <img src="./assets/img/gma.jpg" className="img-rounded img-fluid col-4" /> */}
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <Link className="navbar-brand" to="/">
        그랜드음악학원 v3.1
      </Link>

      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          {user && user.isTeacher && (
            <>
              <NavLink className="nav-item nav-link" to="/Chuls5">
                레슨
              </NavLink>
              <NavLink className="nav-item nav-link" to="/studentsPhone">
                학생폰
              </NavLink>
            </>
          )}
          {user && (user.isTeacher || user.isDriver || user.isCarTeacher) && (
            <>
              <NavLink className="nav-item nav-link" to="/rides">
                탑승명단
              </NavLink>
            </>
          )}

          {user && user.isTeacher && (
            <>
              <NavLink className="nav-item nav-link" to="/evals">
                평가
              </NavLink>
              <NavLink className="nav-item nav-link" to="/ontime">
                등원
              </NavLink>
              <NavLink className="nav-item nav-link" to="/docu">
                동영상 연주곡
              </NavLink>
            </>
          )}
          {!user && (
            <>
              <NavLink className="nav-item nav-link" to="/login">
                Login
              </NavLink>
              <NavLink className="nav-item nav-link" to="/register">
                Register
              </NavLink>
            </>
          )}
          {user && (
            <>
              <span className="nav-link">{`(${user.name})`}</span>
              <NavLink className="nav-item nav-link" to="/logout">
                Logout
              </NavLink>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
